import moment from "moment";
import clevertap from "clevertap-web-sdk";
import { DOMAIN, STORE_CUSTOMIZATION } from "../constants";
import store from "../Redux/Store";
import {
  AUTH_TOKEN_KEY,
  CUSTOM_CATALOG_CATALOGTYPE,
  CUSTOM_CATALOG_OPERATION,
  ROUTES,
  DEVICE_TYPE,
  PREMIUM_PAGE_BACKWARD_COMPATABILITY_APP_VERSION,
  WEB_CONSOLE_ROUTES,
  INDIAN_MOBILE_NO_EXTENSION,
} from "./constants";
import { getLocalState, getSessionState } from "./storage";
import { recordEvent, setCollectionCategoryItems } from "../Redux/Actions";
import { getMobileOperatingSystem } from "./operatingSystem";
import history from "./history";
import { goToWebConsole } from "./goToWabaMarketingPage";
import { ENDPOINTS } from "../Config/apiEndpoints";
import { ToastNotifyError } from "../Components/Toast";
import apiCall from "../Services/api";
import { ROUTE_CONSTANT } from "../constants/routesConstants";

/**
 * Helper function which returns the
 * hardcoded string for the payment method
 * @param {string} method
 * @returns
 */
export const getPaymenthodName = (method) => {
  switch (method) {
    case "googlepay":
      return "Google Pay";
    case "cod":
      return "Cash";
    case "phonepe":
    case "pswitch":
      return "PhonePe";
    case "payzapp":
      return "payZapp";
    case "whatsapp":
      return "WhatsApp";
    case "paytm":
    case "paytmupi":
      return "Paytm";
    case "airtel":
      return "Airtel";
    case "cemi":
      return "emi";
    case "payzapp":
      return "PayZapp";
    case "card":
      return "Credit/Debit Cards";
    default:
      return method;
  }
};

export const isSearchDomainValid = (str) => {
  const validDomainRegex = /^[a-zA-Z0-9.-]+$/; // allow alphanumeric characters, dot and hyphen only inside Domain Name
  return validDomainRegex.test(str);
};

export const getPackageName = (paymentMethod) => {
  const packageNames = {
    googlepay: "com.google.android.apps.nbu.paisa.user",
    phonepe: "com.phonepe.app",
    paytmupi: "net.one97.paytm",
  };
  return packageNames[paymentMethod];
};

export const getUPIPaymentDetails = (deeplink) => {
  if (deeplink) {
    const urlParams = new URLSearchParams("?" + deeplink.split("pay?")[1]);
    const paymentDetailsObj = Object.fromEntries(urlParams.entries());
    return paymentDetailsObj;
  }
};

/**
 * Helper function for addition of the variant product
 * item to the cart for update the cart
 * @param {string} type
 * @param {Object} item
 * @param {Array} cartItems
 * @returns
 */
export const mutateVariantItemWithCartData = (
  type,
  item,
  variant,
  cartItems
) => {
  try {
    const isB2bStore = isStoreTypeB2b();
    let tempCart = [...JSON.parse(JSON.stringify(cartItems))];
    let idx;

    if (isB2bStore) {
      item.discounted_price = "";
      item.price = "";
      variant.discounted_price = "";
    }

    // create important keys in the Item
    item = {
      ...item,
      item_name: item.name,
      item_quantity: item.item_quantity ? item.item_quantity : "1",
      item_price:
        Number(item.item_quantity ? item.item_quantity : "1") * item.price,
      unit_price: item.discounted_price,
      discounted_price: item.discounted_price,
      item_id: item.id,
      item_type: "catalog",
    };

    switch (type) {
      // case for increase in item quantity
      case "add":
        idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);

        if (tempCart[idx] && tempCart[idx].variants_selected) {
          const variantIdx = tempCart[idx].variants_selected.findIndex(
            (el) => el.variant_id === variant.variant_id
          );

          /** check if the variant already exist */
          if (tempCart[idx].variants_selected.length > 0 && variantIdx !== -1) {
            // Increase the variant quantity by one
            tempCart[idx].variants_selected[variantIdx].quantity =
              Number(tempCart[idx].variants_selected[variantIdx].quantity) + 1;
          } else {
            // add new variant to the selected variant list
            tempCart[idx].variants_selected.push({ ...variant, quantity: 1 });
          }
          /** Increase the whole item quantity by one */
          tempCart[idx].item_quantity = Number(tempCart[idx].item_quantity) + 1;
        } else {
          item.variants_selected = [{ ...variant, quantity: 1 }];
          tempCart.push(item);
        }
        break;
      case "remove":
        idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);

        if (tempCart[idx].variants_selected) {
          const variantIdx = tempCart[idx].variants_selected.findIndex(
            (el) => el.variant_id === variant.variant_id
          );

          if (
            Number(tempCart[idx].variants_selected[variantIdx].quantity) === 1
          ) {
            // Remove the item if its a last one
            tempCart[idx].variants_selected.splice(variantIdx, 1);

            if (tempCart[idx].variants_selected.length === 0) {
              tempCart.splice(idx, 1);
            }
          } else {
            // Decrease the variant quantity by one
            tempCart[idx].variants_selected[variantIdx].quantity =
              Number(tempCart[idx].variants_selected[variantIdx].quantity) - 1;

            /** Decrease the whole item quantity by one */
            if (tempCart[idx] && tempCart[idx].length !== 0)
              tempCart[idx].item_quantity =
                Number(tempCart[idx].item_quantity) - 1;
          }
        }
        break;
      case "repeat":
        idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);

        if (
          tempCart[idx].variants_selected &&
          tempCart[idx].variants_selected.length > 0
        ) {
          tempCart[idx].variants_selected[
            tempCart[idx].variants_selected.length - 1
          ].quantity =
            Number(
              tempCart[idx].variants_selected[
                tempCart[idx].variants_selected.length - 1
              ].quantity
            ) + 1;
          tempCart[idx].item_quantity = Number(tempCart[idx].item_quantity) + 1;
        }
        break;
    }

    return tempCart;
  } catch (err) {
    console.log(err);
    return [];
  }
};

/**
 * funciton returns the prmium payment
 * type from the text we are storing in
 * session at the time of the init payment.
 * @param {String} text
 *
 *
 * FOR DLP Comment
 *  if purchase is domain-purchase then return purchase-type as 2
 */
export const getPurchaseTypeFromText = (text) => {
  switch (text) {
    case "theme":
      return 1;
    case "domain":
      return 2;
    case "domain-purchase":
      return 2;
    case "domain-renewal":
      return 2;
    case "domain-marketing":
      return 2;
    case "domain-connect":
      return 3;
    case "google-ads":
      return 4;
    case "google-workspace":
      return 8;
    case "waba-purchase":
      return 14;
    case "subscription-using-coupon":
      return 999;
    case "brand-website":
      return 15;
    case "social-media-share":
      return 16;
    case "subscription":
      return 5;
  }
};

export const getAdStatus = (status) => {
  switch (status) {
    case 1:
    case 2:
      return { code: status, text: "Draft", class: "draft" };
    case 3:
    case 4:
    case 5:
      return { code: status, text: "In Review", class: "in-review" };
    case 6:
      return { code: status, text: "Active", class: "active" };
    case 7:
      return { code: status, text: "Paused", class: "paused" };
    case 8:
      return { code: status, text: "Expired", class: "expired" };
  }
};

export const getCompletedStepsListforAd = (formData) => {
  let tempCompletedSteps = [];

  // First Step
  if (
    formData?.ad_info?.heading1 &&
    formData?.ad_info?.heading2 &&
    formData?.ad_info?.heading3 &&
    formData?.ad_info?.description1 &&
    formData?.ad_info?.description2
  ) {
    tempCompletedSteps.push(1);
  }
  // Second Step
  if (formData?.ad_keywords_list?.length > 0) {
    tempCompletedSteps.push(2);
  }
  //Third Step
  if (formData?.location?.google_location_list?.length > 0) {
    tempCompletedSteps.push(3);
  }
  // Fourth Step
  if (formData?.budget?.days && formData?.budget?.daily_limit) {
    tempCompletedSteps.push(4);
  }

  return tempCompletedSteps;
};

export const getDomainPriceAfterDiscount = (price, discount) => {
  return price - discount < 0 ? 0 : price - discount;
};

/**
 * utility function for android/ios version comparator
 * @param {*} version string
 * @returns {*} boolean
 */
export const versionCompare = (version) => {
  const versionNo = +version || undefined;
  if (versionNo) {
    if (window.ReactNativeWebView) {
      return versionNo >= 1.63;
    } else {
      return versionNo >= 90;
    }
  }

  return false;
};

export const getSubscriptionCartPayload = (
  theme,
  workspace,
  domain,
  cart_id
) => {
  let payload = {
    cart_id,
    amount: 0,
    items: [],
  };

  if (theme && theme.add === "yes") {
    payload.items.push({
      subscription_id: 1,
      price: theme.price,
      data: {
        theme_id: 1443,
        theme_category: "gold",
      },
    });
    payload.amount += isNaN(Number(theme.price)) ? 0 : Number(theme.price);
  }
  if (workspace && workspace.add === "yes") {
    payload.items.push({
      subscription_id: 8,
      price: workspace.price,
      data: {
        domain: domain.domain,
      },
    });
    payload.amount += isNaN(Number(workspace.price))
      ? 0
      : Number(workspace.price);
  }

  if (domain) {
    payload.items.push({
      subscription_id: 2,
      price: domain.price,
      data: {
        year: domain.year,
        domain: domain.domain,
      },
    });
    payload.amount += isNaN(Number(domain.price)) ? 0 : Number(domain.price);
  }

  return payload;
};

export const getDaysDiffFromToday = (date) => {
  const days = moment
    .utc(date)
    .startOf("day")
    .diff(moment().startOf("day"), "days");
  return days;
};

export const doesStoreAddressExist = (storeData) => {
  return (
    (storeData?.address?.address_1 !== "" ||
      storeData?.address?.address_2 !== "") &&
    storeData?.address?.pincode !== ""
  );
};

export const mailToDotpeExpert = (storeData) => {
  const mailString = `mailto:expert@dotpe.in?subject=Support request for Digital Showroom <Store ID -(${
    storeData?.store_id ? storeData?.store_id : ""
  })>&body=%28Please do not edit subject line - Digital Showroom %7CStore ID - %3C${
    storeData?.store_id
  }%3E%29%0D%0A%0D%0ALet our experts know what issue you are facing%0D%0AStart writing below %F0%9F%91%87`;

  // window.open(mailString, "_blank");
  window.location.href = mailString;
};

/**
 * @returns {Object} a Object containing CategoryId->CategoryName
 * */

export function getCategoriesNameMap() {
  const storeCatalog = store.getState().catalogReducer?.store_catalog_data;
  let categoriesIdMap = {};
  storeCatalog.forEach((categoryItem) => {
    categoriesIdMap[categoryItem?.category.id] = categoryItem?.category?.name;
  });
  return categoriesIdMap;
}

/**
 * @returns {Object} a Object containing CategoryId->CategoryName if collections unlocked
 * */

export function getCategoriesNameMapIfCollections() {
  const collection = store.getState().catalogReducer?.collection_categories;
  let categoriesNameMapIfCollections = {};
  !!collection?.length &&
    collection.forEach((collectionItem) => {
      collectionItem?.categories?.map((category) => {
        categoriesNameMapIfCollections[category?.id] = category?.name;
      });
    });
  return categoriesNameMapIfCollections;
}

/**
 * @param {*} id Category Id
 * @returns {array} Containing ProductItems to particular categoryId
 */

export function getCategoryItemsById(id) {
  const storeCatalog = store.getState().catalogReducer?.store_catalog_data;
  const catalogCategory = storeCatalog.find(
    (catId) => id === catId?.category?.id
  );
  return catalogCategory?.items;
}

/**
 * @param {*} id categoryId
 * @returns {Object} itemsid's in object {ItemId -> ""}
 */

export function getCategoryItemsByObject(id) {
  const categoryItems = getCategoryItemsById(id);
  let tempItemsObject = {};
  for (let i = 0; i < categoryItems?.length; i++) {
    tempItemsObject[categoryItems[i]?.id] = "";
  }
  return tempItemsObject;
}

/**
 * @param {*} id CollectionId
 * @returns {Object} {CollectionId : {categoryId : {ItemID : "" }}}
 */

export async function getAllByCollection(id) {
  const collectionCategories =
    store.getState().catalogReducer?.collection_categories;
  const collectionItem = collectionCategories.find(
    (collection) => id === collection?.id
  );

  let tempCategoryItemsObject = {};

  for (let i = 0; i < collectionItem?.categories?.length; i++) {
    tempCategoryItemsObject[collectionItem?.categories[i]?.id] = {};
    const allItems = await getCollectionCategoryItems(
      id,
      collectionItem?.categories[i]?.id
    );
    allItems.forEach(
      (item) =>
        (tempCategoryItemsObject[collectionItem?.categories[i]?.id][item?.id] =
          "")
    );
  }
  return tempCategoryItemsObject;
}

export async function getCollectionCategoryItems(collectionId, categoryId) {
  const collectionCategoryItems =
    store.getState().catalogReducer?.collectionCategoryItems || {};
  if (collectionCategoryItems?.[collectionId]?.[categoryId]) {
    return collectionCategoryItems[collectionId][categoryId];
  }
  const storeId = store.getState().storeReducer.data?.store?.store_id;
  const response = await apiCall({
    method: "GET",
    url: ENDPOINTS.CATALOG.getCollectionCategoryItemData(
      storeId,
      collectionId,
      categoryId
    ),
    parseToJson: true,
  });
  if (response && response?.data?.status) {
    store.dispatch(
      setCollectionCategoryItems({
        collectionId: collectionId,
        categoryId: categoryId,
        items: response?.data?.data,
      })
    );
    return response?.data?.data;
  } else {
    ToastNotifyError(response?.data?.message);
  }
}

export async function getCustomCatalog(data) {
  const catalogType = data?.catalogType;
  const operationType = data?.operationType;
  const id = data.id;

  const customCatalog = store.getState()?.catalogReducer?.custom_catalog;
  let tempCustomCatalog = { ...customCatalog };

  switch (catalogType) {
    case CUSTOM_CATALOG_CATALOGTYPE.COLLECTION:
      if (operationType === CUSTOM_CATALOG_OPERATION.ADD) {
        tempCustomCatalog[id] = await getAllByCollection(id);
      } else if (operationType === CUSTOM_CATALOG_OPERATION.DELETE) {
        delete tempCustomCatalog[id];
      }
      return tempCustomCatalog;

    case CUSTOM_CATALOG_CATALOGTYPE.CATEGORY:
      if (data?.isCollection) {
        let tempCategoryObject = { ...customCatalog[data?.collectionId] };
        if (operationType === CUSTOM_CATALOG_OPERATION.ADD) {
          tempCategoryObject[id] = {};
          const items = await getCollectionCategoryItems(
            data?.collectionId,
            id
          );
          items.forEach((item) => (tempCategoryObject[id][item?.id] = ""));
          tempCustomCatalog[data?.collectionId] = tempCategoryObject;
        } else {
          let tempCategoryObject = { ...customCatalog[data?.collectionId] };
          if (operationType === CUSTOM_CATALOG_OPERATION.DELETE) {
            if (Object.keys(tempCategoryObject)?.length === 1) {
              delete tempCustomCatalog[data?.collectionId];
            } else {
              delete tempCategoryObject[id];
              tempCustomCatalog[data?.collectionId] = tempCategoryObject;
            }
          }
        }
        return tempCustomCatalog;
      } else {
        let tempCategoryObject = { ...customCatalog };
        if (operationType === CUSTOM_CATALOG_OPERATION.ADD) {
          tempCategoryObject[id] = getCategoryItemsByObject(id);
        } else {
          if (operationType === CUSTOM_CATALOG_OPERATION.DELETE) {
            delete tempCategoryObject[id];
          }
        }
        return tempCategoryObject;
      }
    case CUSTOM_CATALOG_CATALOGTYPE.PRODUCT:
      if (data?.isCollection) {
        let tempCategoryObject = { ...customCatalog[data?.collectionId] };
        let tempItemObject = { ...tempCategoryObject[data?.categoryId] };

        if (operationType === CUSTOM_CATALOG_OPERATION.ADD) {
          tempItemObject[id] = "";
          tempCategoryObject[data?.categoryId] = tempItemObject;
          tempCustomCatalog[data?.collectionId] = tempCategoryObject;
        } else {
          if (operationType === CUSTOM_CATALOG_OPERATION.DELETE) {
            if (Object.keys(tempItemObject)?.length === 1) {
              delete tempCategoryObject[data?.categoryId];
              if (Object.keys(tempCategoryObject)?.length < 1) {
                delete tempCustomCatalog[data?.collectionId];
              } else {
                tempCustomCatalog[data?.collectionId] = tempCategoryObject;
              }
            } else {
              delete tempItemObject[id];
              tempCategoryObject[data?.categoryId] = tempItemObject;
              tempCustomCatalog[data?.collectionId] = tempCategoryObject;
            }
          }
        }
        return tempCustomCatalog;
      } else {
        let tempCategoryObject = { ...customCatalog };
        let tempItemObject = { ...tempCategoryObject[data?.categoryId] };
        if (operationType === CUSTOM_CATALOG_OPERATION.ADD) {
          tempItemObject[id] = "";
          tempCategoryObject[data?.categoryId] = tempItemObject;
        } else {
          if (operationType === CUSTOM_CATALOG_OPERATION.DELETE) {
            if (Object.keys(tempItemObject)?.length === 1) {
              delete tempCategoryObject[data?.categoryId];
            } else {
              delete tempItemObject[id];
              tempCategoryObject[data?.categoryId] = tempItemObject;
            }
          }
        }
        return tempCategoryObject;
      }
  }
}

/**
 * @returns {Object} collectionId mapped with collection name
 */

export function getCollectionNameIdMap() {
  const collection = store.getState().catalogReducer?.collection_categories;
  let collectionNameIdMap = {};
  !!collection?.length &&
    collection.forEach((collectionItem) => {
      collectionNameIdMap[collectionItem?.id] = collectionItem?.name;
    });
  return collectionNameIdMap;
}

export function getStoreType() {
  const storeData = store.getState().storeReducer?.data?.store;
  return storeData?.services?.store_type_flag;
}

export function isStoreTypeB2b() {
  return getStoreType() === STORE_CUSTOMIZATION.B2B;
}

const updateCartVariantItems = (variants_selected, fetchedItemVariants) => {
  const modifiedVariantsSelected = variants_selected.map(
    (b2bCartItemVariant) => {
      const selectedVariant = fetchedItemVariants.find(
        (fetchedItemVariant) =>
          fetchedItemVariant.variant_id === b2bCartItemVariant.variant_id
      );
      b2bCartItemVariant.available = selectedVariant.available;
      b2bCartItemVariant.available_quantity =
        selectedVariant.available_quantity;
      return b2bCartItemVariant;
    }
  );
  return modifiedVariantsSelected;
};

export const updateB2bCartItemsDetailsWithFetchedItemDetails = (
  b2bCartItems,
  fetchedItems
) => {
  b2bCartItems.forEach((b2bCartItem) => {
    const fetchedItem = fetchedItems.find((item) => item.id === b2bCartItem.id);
    b2bCartItem.available = fetchedItem.available;
    b2bCartItem.available_quantity = fetchedItem.available_quantity;
    if (
      Array.isArray(b2bCartItem.variants_selected) &&
      !!b2bCartItem.variants_selected?.length
    ) {
      const modifiedVariantsSelected = updateCartVariantItems(
        b2bCartItem.variants_selected,
        fetchedItem.variants
      );
      b2bCartItem.variants_selected = modifiedVariantsSelected;
    }
  });
  return b2bCartItems;
};

export function getCartItemsWithGst(cartItems) {
  const newGstAdjustedItems = cartItems.map((item) => {
    if ("tax" in item) {
      item.gst_info = {
        gst_percentage: item?.tax?.gst,
        tax_rule_id: item?.tax?.id,
        hsn_sac_code: item?.tax?.hsn_sac_code,
      };
      delete item.tax;
    }
    return item;
  });
  return newGstAdjustedItems;
}

export const getCouponIdForSubscriptionPayment = () => {
  const storeData = store.getState().storeReducer?.data?.store;
  const couponForSubscriptionPayment = storeData?.coupon_id_expiry?.filter(
    (coupon) => coupon?.is_combo === 0
  );

  return couponForSubscriptionPayment &&
    couponForSubscriptionPayment?.length > 0
    ? couponForSubscriptionPayment[0]?.coupon_id
    : 0;
};

export const getCouponDiscountForSubscriptionPayment = () => {
  const couponData = store.getState().couponReducer;
  const couponDiscount =
    couponData?.subscriptionPurchaseDetailsUsingCoupons?.coupon
      ?.discount_value || 0;
  return couponDiscount;
};

export function extractSldFromDomain(inputUrl) {
  // Remove "https://", "http://", "www.", and any leading or trailing slashes
  let domain = inputUrl
    .replace("http://", "")
    .replace("https://", "")
    .replace("www.", "")
    .replace(/\/+$/, "");

  // Split the domain by dot (.)
  const parts = domain?.split(".");

  // Extract the first part (before the first dot) i.e sld(second level domain) from domain [ ex: abc.in => abc is SLD]
  const extractedSldFromDomain = parts[0];
  // Check if the length of the extracted domain is greater than 3
  if (extractedSldFromDomain.length >= 3) {
    return extractedSldFromDomain;
  } else {
    return null;
  }
}

export function parseToJson(data) {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
}

export function getMonthName(monthNumber) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[monthNumber - 1];
}

export const checkIsStaging = () => process.env.REACT_APP_CUSTOM_ENV === "DEV";

export const getToken = () =>
  getSessionState(AUTH_TOKEN_KEY) ||
  getLocalState(AUTH_TOKEN_KEY) ||
  store.getState().storeReducer?.data?.auth_token;

export const isDesktop = () => window.screen.width >= 768;

export function getEventPlatform() {
  const isDomainMarketingRoute = [
    WEB_CONSOLE_ROUTES.DOMAIN_SALE,
    WEB_CONSOLE_ROUTES.DOMAIN_COMPLETION,
    ROUTES.DOMAIN_OPEN_SEARCH,
    ROUTES.DOMAIN_LANDING_CART,
    ROUTES.DOMAIN_PURCHASE_SUCCESS,
  ].includes(window.location.pathname);
  let platform = !isDomainMarketingRoute ? "WebConsole" : "Website",
    device = isDesktop() ? "Desktop" : "Mobile";
  if (window?.Android) {
    platform = "Android";
    device = "Mobile";
  }
  if (window?.ReactNativeWebView) {
    platform = "iOS";
    device = "Mobile";
  }
  return { platform, device };
}

export function getUrlParamValue(param) {
  const params = new URLSearchParams(window.location.search);
  return params.get(param) || "";
}

export function getRouteWithParams(Route) {
  const urlParams = new URLSearchParams(window.location.search);
  const storeid = urlParams.get("storeid");
  const token = urlParams.get("token");
  const app_version = urlParams.get("app_version");
  const app_version_code = urlParams.get("app_version_code");
  const version_number = urlParams.get("version_number");
  const channel = urlParams.get("Channel") || urlParams.get("channel");
  const upi_options =
    store.getState().paymentReducer.availableUpiPaymentOptions;
  const additionalParameters = `?token=${token}&storeid=${storeid}&app_version=${app_version}&app_version_code=${app_version_code}&version_number=${version_number}&channel=${channel}&upi_options=${JSON.stringify(
    upi_options
  )}`;
  return `${Route}${additionalParameters}`;
}

export function getWebconsoleUrlWithParams(webconsoleRoute) {
  return getRouteWithParams(webconsoleRoute);
}

export function getPremiumPageUrl() {
  return getWebconsoleUrlWithParams(WEB_CONSOLE_ROUTES.PREMIUM_PAGE);
}

export function getActiveSubscriptionPageUrl() {
  return getWebconsoleUrlWithParams(
    WEB_CONSOLE_ROUTES.ACTIVE_SUBSCRIPTION_STATUS
  );
}

//function to check backward compatability of premium page.
export function shouldRunOldPremiumPageFlow() {
  const urlParams = new URLSearchParams(window.location.search);
  const app_version = urlParams.get("app_version");

  const currentDeviceType = getMobileOperatingSystem();

  const isOldAndroidAppUser =
    currentDeviceType === DEVICE_TYPE.ANDROID &&
    app_version <=
      PREMIUM_PAGE_BACKWARD_COMPATABILITY_APP_VERSION.ANDROID_APP_VERSION;
  const isOldIosAppUser =
    currentDeviceType === DEVICE_TYPE.IOS &&
    app_version <=
      PREMIUM_PAGE_BACKWARD_COMPATABILITY_APP_VERSION.IOS_APP_VERSION;

  return isOldAndroidAppUser || isOldIosAppUser;
}

export const goToDashyHomepage = () => goToWebConsole(getPremiumPageUrl());
export const goToDashyActiveSubscriptionPage = () =>
  goToWebConsole(getActiveSubscriptionPageUrl());

export function handlePremiumPageRouting(
  digitalDukaanRouteFromParam,
  goToDashyFromParam
) {
  const { THEME_DISCOVER, THEME_HISTORY } = ROUTE_CONSTANT;
  const storeData = store.getState().storeReducer.data;
  const isOldFlow = shouldRunOldPremiumPageFlow();
  const isPremium = storeData?.store?.premium;

  const digitalDukaanRoute = isPremium ? THEME_HISTORY : THEME_DISCOVER;
  const goToDashy = isPremium
    ? goToDashyActiveSubscriptionPage
    : goToDashyHomepage;

  const finalRouteToRedirect =
    digitalDukaanRouteFromParam || digitalDukaanRoute;
  const finalGoToDashy = goToDashyFromParam || goToDashy;

  isOldFlow
    ? history.push(getRouteWithParams(finalRouteToRedirect))
    : finalGoToDashy();
}

export const clevertapEventPush = (eventName, additionalData = {}) => {
  const storeData = store.getState().storeReducer.data;
  const eventPlatforms = getEventPlatform();
  const clevertapPayload = {
    store_id: storeData?.store?.store_id,
    device: eventPlatforms.device,
    platform: eventPlatforms.platform,
    ...additionalData,
  };
  const apiPayload = {
    event_name: eventName,
    data: clevertapPayload,
  };
  store.dispatch(recordEvent(apiPayload));
  if (clevertap?.event) {
    clevertap.event.push(eventName, clevertapPayload);
  }
};

export const clevertapProfilePush = (storeData) => {
  if (clevertap && clevertap?.profile) {
    clevertap.profile.push({
      Site: {
        Name: storeData?.store?.store_name,
        Identity: `${storeData?.phone}`,
        Phone: `${storeData?.phone}`,
        // Identity: storeData?.phone
        //   ? storeData?.phone.includes(INDIAN_MOBILE_NO_EXTENSION)
        //     ? storeData?.phone
        //     : `${INDIAN_MOBILE_NO_EXTENSION}${storeData?.phone}`
        //   : "",
        // Phone: storeData?.phone
        //   ? storeData?.phone.includes(INDIAN_MOBILE_NO_EXTENSION)
        //     ? storeData?.phone
        //     : `${INDIAN_MOBILE_NO_EXTENSION}${storeData?.phone}`
        //   : "",
      },
    });
  }
};

export const getDomainSubscriptionInfo = () => {
  const subscriptionDetails =
    store.getState().themesReducer.subscriptionDetails;

  const hasExternalDomain = !!subscriptionDetails?.externalDomain?.status;
  const domainInfo = subscriptionDetails?.domain;
  const hasDomain = !!domainInfo?.status;
  const daysDifference = hasDomain && getDaysDiffFromToday(domainInfo?.expiry);
  const isOutOfGracePeriod =
    domainInfo?.status === DOMAIN.STATUS.OUT_OF_GRACE_PERIOD;
  const isExpired =
    isOutOfGracePeriod || domainInfo?.status === DOMAIN.STATUS.EXPIRED;
  const isAboutToExpire =
    hasDomain && daysDifference < 30 && daysDifference >= 0;
  return {
    hasExternalDomain,
    hasDomain,
    isOutOfGracePeriod,
    isExpired,
    isAboutToExpire,
  };
};

export const shouldShownDomainUpsell = () => {
  const {
    hasExternalDomain,
    hasDomain,
    isOutOfGracePeriod,
    isExpired,
    isAboutToExpire,
  } = getDomainSubscriptionInfo();
  return (
    !hasExternalDomain &&
    (!hasDomain || isOutOfGracePeriod || isExpired || isAboutToExpire)
  );
};

export function getDomainName() {
  const subscriptionDetails = store.getState().themeReducer.subscriptionDetails;
  return (
    subscriptionDetails?.domain?.domain_name ||
    subscriptionDetails?.externalDomain?.domain_name
  );
}

export const isBrandWebsiteUpsellAllowed = () => {
  const { brand_plus_upsell_discount = 0, brand_pro_upsell_discount = 0 } =
    store.getState().storeReducer?.data?.store?.constants || {};

  return !!(brand_plus_upsell_discount || brand_pro_upsell_discount);
};
