import { takeEvery, put } from "redux-saga/effects";
import apiCall from "../../Services/api";
import {
  PREMIUM_CALLBACK,
  SET_SUBSCRIPTION_CART,
  INITIATE_SUBSCRIPTION_PURCHASE,
  INITIATE_WABA_PURCHASE,
  INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON,
  INITIATE_SOCIAL_SHARE_PURCHASE,
  INITIATE_SUBSCRIPTION_PAYMENT,
} from "../ActionTypes";
import { savePrePaymentData } from "../Actions";
import { ToastNotifyError } from "../../Components/Toast";
import { setPrePaymentData, setPurchaseType } from "../../Utils/paymentHelper";
import {
  CONSTANTS,
  removeLocalState,
  setLocalState,
} from "../../Utils/storage";
import { ENDPOINTS } from "../../Config/apiEndpoints";
import { parseToJson } from "../../Utils/common";

function* premiumUpdateCallback(actions) {
  try {
    const payload = { ...actions.data.data, device: "Mobile" };
    payload.platform = window.ReactNativeWebView ? "iOS" : "Android";
    const response = yield apiCall({
      method: "POST",
      data: payload,
      url: `/dotk/vm1/premium/addRequestToCallback`,
    });
    if (response && response.data.status) {
      actions.data && actions.data.callback(true);
    }
  } catch (err) {
    ToastNotifyError(err.message);
  }
}

function* setSubscriptionCart(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/premium/setSubscriptionCart`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response.data.status) {
      setLocalState(
        CONSTANTS.LOCAL.SUBSCRIPTION_CART_ID_KEY,
        response.data.data
      );
    }
  } catch (err) {
    ToastNotifyError(err.message);
    removeLocalState(CONSTANTS.LOCAL.SUBSCRIPTION_CART_ID_KEY);
  }
}

function* initSubscriptionPurchase(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/premium/initiateSubscriptionPurchase/${actions.data.cart_id}`,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPrePaymentData(response?.data?.data);
      setPurchaseType(actions.data.premiumPurchaseType);
      yield put(savePrePaymentData(response?.data?.data));
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    } else {
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    }
  } catch (err) {
    ToastNotifyError(err.message);
  }
}

function* initSubscriptionPayment(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: "/dotk/vm1/premium/initiateEcommPayment",
      data: actions.data,
    });
    if (response && response?.data?.status) {
      response.data.data = parseToJson(response.data.data);
      setPurchaseType("subscription");
      setPrePaymentData(response?.data?.data);
      yield put(savePrePaymentData(response?.data?.data));
      actions.callback && actions.callback(response?.data?.status, response?.data?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status);
    }
  } catch (err) {
    ToastNotifyError("Cannot enable subscription currently. Please try later.");
  }
}

function* initSocialSharePurchase(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.MARKETING.initiateSocialSharePurchase(),
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPrePaymentData(response?.data?.data);
      setPurchaseType("social-media-share");
      yield put(savePrePaymentData(response?.data?.data));
    }
    if (actions.callback) {
      actions.callback(response?.data?.status, response?.data);
    }
  } catch (err) {
    ToastNotifyError(err.message);
    actions.callback(false);
  }
}

function* initWabaPurchase(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.MARKETING.initiateWABAPurchase(),
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPrePaymentData(response?.data?.data);
      setPurchaseType("waba-purchase");
      yield put(savePrePaymentData(response?.data?.data));
    }
    if (actions.callback) {
      actions.callback(response?.data?.status, response?.data);
    }
  } catch (err) {
    ToastNotifyError(err.message);
    actions.callback(false);
  }
}

function* initSubscriptionPurchaseUsingCoupon(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${ENDPOINTS.initiateSubscriptionsPurchaseUsingCoupon}`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPurchaseType("subscription-using-coupon");
      yield put(savePrePaymentData(response?.data?.data));
      actions.callback &&
        actions.callback(response?.data?.status, response?.data?.data);
    } else {
      actions.callback && actions.callback(response?.data?.status);
    }
  } catch (err) {
    ToastNotifyError(err.message);
  }
}

export default function* root() {
  yield takeEvery(INITIATE_SUBSCRIPTION_PAYMENT, initSubscriptionPayment);
  yield takeEvery(PREMIUM_CALLBACK, premiumUpdateCallback);
  yield takeEvery(SET_SUBSCRIPTION_CART, setSubscriptionCart);
  yield takeEvery(INITIATE_SUBSCRIPTION_PURCHASE, initSubscriptionPurchase);
  yield takeEvery(INITIATE_WABA_PURCHASE, initWabaPurchase);
  yield takeEvery(INITIATE_SOCIAL_SHARE_PURCHASE, initSocialSharePurchase);
  yield takeEvery(INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON, initSubscriptionPurchaseUsingCoupon);
}
